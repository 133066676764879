import React, { useState, useEffect, useLayoutEffect, useCallback } from "react";
import mojs from "mo-js"
import listItem from '../list.svg';

const ShoppingListCreate = () => {

    const [tetel, setTetel] = useState("")
    const [interim, setInterim] = useState("")
    const [lista, setLista] = useState([])

    const speechRecog = () => {
        window.SpeechRecognition = window.webkitSpeechRecognition || window.SpeechRecognition;
        let finalTranscript = '';
        try {
            let recognition = new window.SpeechRecognition();
            recognition.interimResults = true;
            recognition.maxAlternatives = 10;
            recognition.continuous = true;
            recognition.onresult = (event) => {
                let interimTranscript = '';
                for (let i = event.resultIndex, len = event.results.length; i < len; i++) {
                    let transcript = event.results[i][0].transcript;
                    if (event.results[i].isFinal) {
                        finalTranscript = transcript;
                    } else {
                        interimTranscript += transcript;
                    }
                    console.log("INTERIM TRANSCRIPT: ", interimTranscript);
                    console.log("FINAL TRANSCRIPT: ", finalTranscript);
                    setInterim(interimTranscript);
                    if (finalTranscript.length > 5) {
                        setTetel(finalTranscript);
                    }
                }
                let inputTODO = document.getElementById('todoInput');
                inputTODO.value = finalTranscript;// + interimTranscript;
            }
            recognition.onspeechend = () => {
                recognition.stop();
                let inputTODO = document.getElementById('todoInput');
                if(inputTODO) inputTODO.value = finalTranscript;// + interimTranscript;
            }
            recognition.start();
        } catch (e) {
            console.log(e);
        }
        console.log(finalTranscript);
        return finalTranscript;
    }

    /*useEffect(() => {
        speechRecog()
    }, [])*/

    const handleChange = (project) => {
        setTetel(project.target.value)
    }

    const useClapAnimation = ({ clapEl }) => {
        const [animationTimeline, setAnimationTimeline] = useState(() => new mojs.Timeline())

        useLayoutEffect(() => {
            if (!clapEl) { return }
            const tlDuration = 300
            const scaleButton = new mojs.Html({
                el: clapEl,
                duration: tlDuration,
                scale: { 1.3: 1 },
                easing: mojs.easing.ease.out,
            })
            const triangleBurst = new mojs.Burst({
                parent: clapEl,
                radius: { 80: 200 },
                count: 10,
                angle: 30,
                children: {
                    shape: 'polygon',
                    radius: { 9: 0 },
                    stroke: 'rgba(20, 86, 211, 1)',
                    strokeWidth: 2,
                    angle: 210,
                    delay: 30,
                    speed: 0.2,
                    duration: tlDuration,
                    easing: mojs.easing.bezier(0.1, 1, 0.3, 1)
                }
            })
            const circleBurst = new mojs.Burst({
                parent: clapEl,
                radius: { 80: 160 },
                count: 10,
                angle: 25,
                duration: tlDuration,
                children: {
                    shape: 'circle',
                    fill: 'rgba(145,165,166,0.5)',
                    delay: 30,
                    speed: 0.2,
                    radius: { 6: 0 },
                    easing: mojs.easing.bezier(0.1, 1, 0.3, 1)
                }
            })

            clapEl.style.transform = 'scale(1,1)'
            const newAnimationTimeline = animationTimeline.add([scaleButton, triangleBurst, circleBurst])
            setAnimationTimeline(newAnimationTimeline)
        }, [clapEl])
        return animationTimeline
    }
    const [{ clapRef }, setRefState] = useState({})
    const setRef = useCallback(
        (node) => {
            setRefState(prevRefState => ({
                ...prevRefState,
                [node.dataset.refkey]: node
            }))
        }, [])
    const animationTimeline = useClapAnimation({
        clapEl: clapRef,
    })

    const handleSubmit = (e) => {
        e.preventDefault();
        animationTimeline.replay()
        if (tetel !== "") setLista([...lista, tetel])
        setTetel("")
    }


    return (
        <div>
            <form onSubmit={handleSubmit} >
                <h5 className="font_BigBlue">Bevásárló lista_</h5>
                <ul>
                    {lista && lista.map((listaElem) => {
                        return (
                            <li style={{ color: 'white' }} key={listaElem} >
                                <span style={{position:'relative', top: '5px', margin: '0px 10px 10px 10px'}}><svg xmlns="http://www.w3.org/2000/svg" width="12pt" height="12pt" viewBox="0 0 12 12" version="1.1">
                                    <g id="surface1">
                                        <path style={{ stroke: 'none', fillRule: 'nonzero', fill: 'rgb(53, 84, 212)', fillOpacity: 1 }} d="M 5.976562 11.953125 C 9.277344 11.953125 11.953125 9.277344 11.953125 5.976562 C 11.953125 2.675781 9.277344 0 5.976562 0 C 2.675781 0 0 2.675781 0 5.976562 C 0 9.277344 2.675781 11.953125 5.976562 11.953125 Z M 5.976562 0.933594 C 8.761719 0.933594 11.019531 3.191406 11.019531 5.976562 C 11.019531 8.761719 8.761719 11.019531 5.976562 11.019531 C 3.191406 11.019531 0.933594 8.761719 0.933594 5.976562 C 0.933594 3.191406 3.191406 0.933594 5.976562 0.933594 Z M 5.976562 0.933594 " />
                                        <path style={{ stroke: 'none', fillRule: 'nonzero', fill: 'rgb(53, 84, 212)', fillOpacity: 1 }} d="M 6.351562 8.21875 L 8.964844 5.976562 L 6.351562 3.734375 L 6.351562 4.855469 L 2.988281 4.855469 L 2.988281 7.097656 L 6.351562 7.097656 Z M 6.351562 8.21875 " />
                                    </g>
                                </svg></span>
                                {listaElem}
                            </li>
                        )
                    })}
                </ul>
                <div className="input-field">
                    <label htmlFor="title"></label>
                    <input className="font_8" autoComplete="off" type="text" id="todoInput" onChange={handleChange} value={tetel} />
                </div>

                <div className="input-field">
                    <button ref={setRef} data-refkey="clapRef" style={{ backgroundColor: "#241CED" }} className="btn lighten 1 z-depth-0">hozzáadom a Listához</button>
                </div>
            </form>
            <div className="font_8">{interim}</div>
            <button onClick={() => speechRecog()} style={{ backgroundColor: "#241CED" }} className="btn lighten 1 z-depth-0">hangfelvétel indítása</button>
        </div>
    )
}

export default ShoppingListCreate
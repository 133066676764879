import React from "react"
import MiddleButton from "./Components/MiddleButton"
import { connect } from "react-redux";

const MiddleBox = (props) => {
    const { auth, profile } = props;
    let linkTo = '/signin';
    let btnText = 'Join Us Now!';
    if(auth.uid){
        btnText = 'Create Event_';
        linkTo = '/regevent';
    }
    return (
        <div>
            <div className="middleBoxContainer darkBG" style={{ padding: "10px", border: "1px solid rgba(36, 28,  237, 1)" }} >{props.children}</div>
            <div style={{ marginRight: "40px", marginLeft: "40px", height: "40px", borderLeft: "1px solid rgba(36, 28,  237, 1)", borderRight: "1px solid rgba(36, 28,  237, 1)" }} ></div>
            <div className="joinBtn" ><MiddleButton linkTo={linkTo} btnText={btnText} /></div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}
export default connect(mapStateToProps)(MiddleBox)
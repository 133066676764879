import React from "react"
import Spotlight from "./Components/Spotlight"

const SpotlightMasters = ({ users }) => {

    users = users ? users : [];
    const spotlightItems1 = users.slice( 0, 3 ).map(spotlight => <Spotlight key={spotlight.firstName} spotlight={spotlight} />);
    const spotlightItems2 = users.slice( 3, 6 ).map(spotlight => <Spotlight key={spotlight.firstName} spotlight={spotlight} />);

    return (
        <div>
            <div className="e31line" ></div>
            <div className="darkBG">
                <div style={{ padding: "5px", }}>
                    <div className='rowSptlght' >{spotlightItems1}</div>
                </div>
                <div style={{ padding: "5px", }}>
                    <div className='rowSptlght' >{spotlightItems2}</div>
                </div>
            </div>
        </div>
    )

}

export default SpotlightMasters
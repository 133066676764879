import React from "react"
import Member from "./Components/Member"

const LatestMembers = ({ users }) => {
    let i = 0;
    let usersArray = [];
    //console.log(moment().unix())
    users ? usersArray = users.slice().sort((a,b) => (a.joinedAt > b.joinedAt) ? 1 : ((b.joinedAt > a.joinedAt) ? -1 : 0)): usersArray = [];
    return (
        <div>
            <div className="e31line" ></div>
            <div>
                {usersArray && usersArray.slice(Math.max(usersArray.length - 4, 0)).reverse().map((user) => {
                    i++;
                    return (
                        <Member key={user.id} user={user} bg={i} />
                    )
                })}
            </div>
        </div>
    )

}

export default LatestMembers
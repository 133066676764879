import React, { useState, useEffect, useCallback } from "react";
import '../App.css';
import '../Chatbot.css';
import '../config';
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from 'react-redux-firebase';
import { Redirect } from "react-router-dom";
import { createProject } from "../store/actions/projectActions";
import ProjectList from "../ComponentBoxes/ProjectList";
import ProjectForm from "../ComponentBoxes/Components/Forms/ProjectForm";

const ActivityDiary = (props) => {

    const [activity, setActivity] = useState({})

    const { projects, auth } = props;
    if (!auth.uid) {
        return <Redirect to='/signin' />
    }

    return (
        <>
            <h1 className="darkBG" style={{ padding: "24px" }}>Apus tevékenységi naplója</h1>
            <ProjectForm />
            <div className="dashboard container" style={{ width: "50%", display: "inline-block", paddingTop: "20px", paddingLeft: "20px"}}>
                {/* <div className="row"> */}
                    <div className="col s12 m6" >
                        <ProjectList projects={projects} />
                    </div>
                {/* </div> */}
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile,
        loading: state.auth.loading,

        users: state.firestore.ordered.users,
        projects: state.firestore.ordered.projects,
        events: state.firestore.ordered.events,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        createProject: (creds) => dispatch(createProject(creds)),
    }
}

export default compose( connect(mapStateToProps, mapDispatchToProps), firestoreConnect([{collection:'users'}, {collection:'projects'}]) )(ActivityDiary)
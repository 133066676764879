import React from "react"
import moment from "moment";
import Countdown from 'react-countdown-now';

const VerboseEvent = ({ event }) => {

    //console.log(moment().unix());
    //console.log(moment(moment.unix(event.start)).unix());
    let dateDiff = moment(moment.unix(event.start)).unix() - moment().unix();
    dateDiff = dateDiff * 1000;
    let isEventDateClose = false;
    if (dateDiff <= 86400000) {
        isEventDateClose = true;
    }
    return (
        <div className="darkBG" style={{ padding: "20px", borderTop: "1px solid rgba(36, 28, 237, 1)" }}>
            <div className="font_LB_big" >{event.name}</div>
            {isEventDateClose ? <div style={{ color: "LightSeaGreen", display: "flex", justifyContent: "space-between" }}>Time remaining until event start <Countdown
                date={Date.now() + dateDiff}
                intervalDelay={1000}
                precision={0}
                renderer={(props) => {return (<div><span>{props.hours}:</span><span>{props.minutes}:</span><span>{props.seconds}</span></div>)}}
            /></div> : null}
            <div className="font_8" >{event.description}</div>
            <div className="font_LB" >{moment.unix(event.start).format('YYYY-MM-DD')}</div>
        </div>
    )
}

export default VerboseEvent
const initState = {
    loading: false,
    eventError: '',
}

const eventReducer = (state = initState, action) => {
    switch (action.type) {
        case 'EVENT_LOADING':
            console.log('creating event', action.event)
            return {
                ...state,
                loading: true,
            }
        case 'CREATE_EVENT':
            console.log('created event', action.event)
            return {
                ...state,
                loading: false,
            }
        case 'CREATE_EVENT_ERROR':
            console.log('create event error', action.err);
            return {
                ...state,
                loading: false,
                eventError: action.err.message
            }
        default:
            return {
                ...state,
            }
    }
}

export default eventReducer
import React from 'react';
import { NavLink } from "react-router-dom";

const SignedOutLinks = () => {
    return (
        <>
            <NavLink to="/home" className="navbarlink">Home</NavLink>
            <NavLink to="/signin" className="navbarlink2">Login</NavLink>
            <NavLink to="/signup" className="navbarlink3">Register</NavLink>
        </>
    )
}

export default SignedOutLinks
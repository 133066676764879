const initState = {
    loading: false,
    eventError: '',
}

const experimentalReducer = (state = initState, action) => {
    switch (action.type) {
        case 'SMS_LOADING':
            //console.log('creating sms', action.data)
            return {
                ...state,
                loading: true,
            }
        case 'CREATE_SMS':
            console.log('created SMS with text', action.data.smsText)
            return {
                ...state,
                loading: false,
            }
        case 'CREATE_SMS_ERROR':
            console.log('create sms error', action.err);
            return {
                ...state,
                loading: false,
                eventError: action.err.message
            }
        default:
            return {
                ...state,
            }
    }
}

export default experimentalReducer
import React from "react"
import thumbnail from "../../images/default.png"
import moment from "moment";

const Member = ({user, bg}) => {
    let darkBG = false;
    const imgSrc = user.profilePic?user.profilePic:thumbnail;
    if( bg %2 === 1 ){
        darkBG = true;
    }

    return (
    <div className={darkBG ? "darkBG rowSptlght" : "rowSptlght"} style={{padding: "5px",}}>
        <img style={{padding: "5px", width: "46px", height: "46px"}} src={imgSrc} alt='Loading...' />
        <div>
            <p className="font_LB">{user.firstName} {user.lastName}</p>
            <p className="font_8" >Joined on_ { moment.unix(user.joinedAt).format('YYYY-MM-DD') } </p>
        </div>
    </div>
    )
}

export default Member
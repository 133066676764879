import React from "react"
import moment from "moment";

const Event = ({event}) => (
    <div>
        <div className="e1line" ></div>
        <div className="darkBG e10paddin">
            <p className="font_8 efontblue">{event.name}</p>
            <br/>
            <p className="font_8">Start Date: {moment.unix(event.start).format('YYYY-MM-DD')}</p>
            <p className="font_8">End Date: {moment.unix(event.end).format('YYYY-MM-DD')}</p>
            <br/>
            <p className="font_8">Location: {event.location}</p>
        </div>
    </div>
)

export default Event
import React from "react"
import VerboseEvent from "./Components/VerboseEvent"

const LatestNews = ({ events }) => {

    let eventsArray = [];
    events ? eventsArray = events.slice().sort((a, b) => (a.start > b.start) ? 1 : ((b.start > a.start) ? -1 : 0)) : eventsArray = [];
    return (
        <div style={{ position: "relative", top: "175px" }}>
            <div className="e3line" ></div>
            <div >
            {eventsArray && eventsArray.slice(0, 2).map((event) => {
                    return (
                        <VerboseEvent key={event.id} event={event} />
                    )
            })}
            </div>
        </div>
    )
}

export default LatestNews
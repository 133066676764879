export const saveSms = (data) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        dispatch({type: 'SMS_LOADING'});
        //make async call to db
        const firestore = getFirestore();
        //const profile = getState().firebase.profile;
        const authorId = getState().firebase.auth.uid;
        firestore.collection('smsek').add({
            ...data,
            authorId: authorId,
            createdAt: new Date()
        }).then(() => {
            dispatch({ type: 'CREATE_SMS', data: data });
        }).catch((err) => {
            dispatch({type: 'CREATE_SMS_ERROR', err});
        })
        
    }
};
const initState = {
    authError: null,
    loading: false,
}

const authReducer = (state = initState, action) => {
    switch (action.type) {
        case 'LOADING':
            console.log('loading...')
            return {
                ...state,
                loading: true,
            }
        case 'LOGIN_ERROR':
            console.log('login fail')
            return {
                ...state,
                authError: 'Login failed',
                loading: false,
            }
        case 'LOGIN_SUCCESS':
            console.log('login success')
            return {
                ...state,
                loading: false,
                authError: null
            }
        case 'SIGNOUT_SUCCESS':
            console.log('signout success');
            return {
                ...state,
                loading: false,
            }
        case 'SIGNUP_SUCCESS':
            console.log('Signup success');
            return {
                ...state,
                loading: false,
                authError: null
            }
        case 'SIGNUP_ERROR':
            console.log('Signup error');
            alert('Signup error, bad secret code.');
            return {
                ...state,
                loading: false,
                authError: action.err.message
            }
        case 'EDITPROFILE_SUCCESS':
            console.log('Edit profile success');
            return {
                ...state,
                loading: false,
            }
        case 'EDITPROFILE_ERROR':
            console.log('Edit profile error');
            return {
                ...state,
                loading: false,
                authError: action.err.message
            }
        default:
            return state
    }
}

export default authReducer
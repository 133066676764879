import React from "react";
import { editProfile } from "../../../store/actions/authActions";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from 'react-redux-firebase';
import FileUploader from "react-firebase-file-uploader";
import firebase from "firebase";
import {Redirect} from "react-router-dom";

class EditProfile extends React.Component {

    state = {
        uid: this.props.auth.uid,
        createdAt: this.props.auth.createdAt,
        avatar: "",
        isUploading: false,
        progress: 0,
        photoURL: this.props.auth.photoURL
    }

    handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });
    handleProgress = progress => this.setState({ progress });
    handleUploadError = error => {
        this.setState({ isUploading: false });
        console.error(error);
    };
    handleUploadSuccess = filename => {
        this.setState({ avatar: filename, progress: 100, isUploading: false });
        firebase
            .storage()
            .ref("images")
            .child(filename)
            .getDownloadURL()
            .then(url => this.setState({ photoURL: url }));
    };

    handleChange = (event) => {
        this.setState({
            [event.target.id]: event.target.value
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        console.log('Sending edited user data...', this.state);
        this.props.editProfile(this.state);
    }

    render() {
        const { loading, auth } = this.props;
        if(!auth.uid) return <Redirect to='/' />
        if (loading) {
            return (<img style={{ width: "200px", height: "200px", marginLeft: "38px", }} src={require('../../../images/e-servant_loading6.gif')} alt="loading..." />);
        } else {
            return (
                <form onSubmit={this.handleSubmit}>
                    <h5 style={{ paddingTop: "10px !important" }} className="font_10">EDIT PROFILE_</h5>
                    <div className="input-field">
                        <label className="font_8" htmlFor="firstName">First Name</label>
                        <input autoComplete="off" className="font_8" type="text" id="firstName" onChange={this.handleChange} />
                    </div>
                    <div className="input-field">
                        <label className="font_8" htmlFor="lastName">Last Name</label>
                        <input autoComplete="off" className="font_8" type="text" id="lastName" onChange={this.handleChange} />
                    </div>
                    <div className="input-field">
                        <label className="font_8" htmlFor="firstName">Phone Number</label>
                        <input autoComplete="off" className="font_8" type="text" id="phoneNumber" onChange={this.handleChange} />
                    </div>
                    <div className="input-field">
                        <label className="font_8" htmlFor="firstName">SMS Key</label>
                        <input autoComplete="off" className="font_8" type="text" id="smsKey" onChange={this.handleChange} />
                    </div>
                    <label>Avatar:</label>
                    {this.state.isUploading && <p>Progress: {this.state.progress}</p>}
                    {this.state.photoURL && <img alt="Loading..." src={this.state.photoURL} />}
                    <FileUploader
                        accept="image/*"
                        name="avatar"
                        randomizeFilename
                        storageRef={firebase.storage().ref("images")}
                        onUploadStart={this.handleUploadStart}
                        onUploadError={this.handleUploadError}
                        onUploadSuccess={this.handleUploadSuccess}
                        onProgress={this.handleProgress}
                    />

                    <div className="input-field">
                        <button style={{ backgroundColor: "#241CED" }} className="btn lighten 1 z-depth-0">send</button>
                    </div>
                </form>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.authError,
        auth: state.firebase.auth,
        loading: state.auth.loading,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        editProfile: (newUser) => dispatch(editProfile(newUser))
    }
}

export default compose(connect(mapStateToProps, mapDispatchToProps), firestoreConnect([{ collection: 'users' }, { collection: 'events' }]))(EditProfile)
import React, { useEffect } from "react";
//import hljs from "highlight.js";

// THIS IS VERY IMPORTANT
// import "highlight.js/styles/github.css";

export default function CodeText(props) {

//   useEffect(() => {
//     hljs.highlightAll();
//   });

const regex = /<code>(.+?)<\/code>/;
const match = props.code.match(regex);
let textBetweenTags = "asd";
if (match) {
    textBetweenTags = match[1];
}

  return (
    <>
      {/* Example with raw JS */}
      <pre>
        <code> {props.code} </code>
      </pre>
    </>
  );
}

import React, { useState, useEffect, useCallback } from "react";
import '../App.css';
import '../Chatbot.css';
import '../config';
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from 'react-redux-firebase';
import { Redirect } from "react-router-dom";
import CodeText from "../ComponentBoxes/Components/CodeText";
import huFlag from "../hu.svg";
import enFlag from "../en.svg";

const ChatbotFn = (props) => {

    const [status, setStatus] = useState("")
    const [model, setModel] = useState({})
    useEffect(() => {
        // if(status === "" && Object.keys(model).length === 0) {
        //     fetch(global.config.url.speakbot.api)
        //     .then(response => response.json())
        //     .then(data => setModel(data));
        // }
        if (status !== "") {
            let bot = document.getElementById('bot');
            if (bot) {
                bot.className = '';
                status && bot.classList.add(status);
            }
        }
    }, [status])

    const [message, setMessage] = useState("")
    const [langToggle, setLangToggle] = useState(false)
    const [interim, setInterim] = useState("")
    const [chatTexts, setChatTexts] = useState([])
    const [chatResponses, setChatResponses] = useState([])

    const { auth } = props;
    if (!auth.uid) {
        return <Redirect to='/signin' />
    }

    const STT = () => {
        const SpeechRecognition = window.speechRecognition || window.webkitSpeechRecognition;
        // const SpeechRecognitionEvent = SpeechRecognitionEvent || webkitSpeechRecognitionEvent

        let recognition = new SpeechRecognition();
        recognition.continuous = false;
        if (langToggle) {
            recognition.lang = 'en-US';
        } else {
            recognition.lang = 'hu-HU';
        }
        recognition.interimResults = false;
        recognition.maxAlternatives = 1;

        recognition.start();
        recognition.onresult = (event) => {
            // alert(event.results[0][0].transcript);
            setInterim(event.results[0][0].transcript);
        }
        recognition.onspeechend = () => {
            recognition.stop();
        }
        recognition.onnomatch = (event) => {
            alert("NO MATCH");
        }
        recognition.onerror = (event) => {
            // alert(event.error);
            console.log(event.error);
        }
    }

    const speechRecog = () => {
        //var window.SpeechRecognition = window.webkitSpeechRecognition || window.SpeechRecognition;
        const SpeechRecognition = window.speechRecognition || window.webkitSpeechRecognition;
        let finalTranscript = '';
        let interimTranscript = '';
        try {
            let recognition = new SpeechRecognition();
            recognition.interimResults = true;
            recognition.maxAlternatives = 10;
            recognition.continuous = true;
            recognition.onresult = (event) => {
                // alert(event.results[0][0].transcript);
                interimTranscript = '';
                for (let i = event.resultIndex, len = event.results.length; i < len; i++) {
                    let transcript = event.results[i][0].transcript;
                    if (event.results[i].isFinal) {
                        finalTranscript = transcript;
                    } else {
                        interimTranscript += transcript;
                    }
                    console.log("INTERIM TRANSCRIPT: ", interimTranscript);
                    console.log("FINAL TRANSCRIPT: ", finalTranscript);
                    if (interimTranscript != "") {
                        setInterim(interimTranscript);
                    }
                    // if (finalTranscript.length > 5) {
                    //     setMessage(finalTranscript);
                    // }
                }
                // let inputTODO = document.getElementById('chatmsg');
                // inputTODO.value = finalTranscript;
            }
            recognition.onspeechend = () => {
                recognition.stop();
                // let inputTODO = document.getElementById('chatmsg');
                // if (inputTODO) inputTODO.value = finalTranscript;
            }
            recognition.start();
        } catch (e) {
            console.log(e);
        }
        console.log("Végső szöveg: ", finalTranscript);
        return finalTranscript;
    }

    const handleCopy = () => {
        if (interim != "") {
            setMessage(interim);
            setInterim("")
        }
    }
    const handleColor = (szin) => {
        let inputElem = document.getElementById('interimText');
        inputElem.style.color = szin;
    }

    const handleChange = (event) => {
        setStatus("thinking")
        setMessage(event.target.value)
    }

    const playAudio = (audio) => {
        return new Promise(res => {
            audio.play()
            audio.onended = res
        })
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        // let url = global.config.url.speakbot.dev
        let url = global.config.url.speakbot.prod
        if (langToggle){
            url = url + "\"" + message + "\"" + "&lang=" + "en" + "&prompt=" + props.auth.uid;
        }
        else {
            url = url + "\"" + message + "\"" + "&lang=" + "hu" + "&prompt=" + props.auth.uid;
        }
        if (message !== "") setChatTexts([...chatTexts, message])
        setMessage("")
        setStatus("computing")
        fetch(url).then(function (response) {
            console.log(response);
            return response.json();
        }).then(async function (data) {
            setChatResponses([...chatResponses, data.msg])
            let audio_str = 'data:audio/wav;base64,' + data.wavstr;
            let myAudio = new Audio(audio_str);
            setStatus("speaking");
            await playAudio(myAudio)
            setStatus("neutral");
        }).catch(function (e) {
            console.log("Booo", e);
        });
    }

    const mouseOverAction = (event) => {
        setStatus("listening");
        // speechRecog();
        STT();
    }

    const modelResponse = (e) => {
        // e.preventDefault();
        // Setting up the net
        const net = new window.brain.recurrent.LSTM();
        net.fromJSON(model);
        let txt = message.replace('?', '');
        txt = txt.toLowerCase();
        const output = net.run(txt);
        console.log(output);
        let url = global.config.url.speakbot.devtest
        url = url + "\"" + output + "\"";
        if (message !== "") setChatTexts([...chatTexts, message])
        setMessage("")
        setStatus("computing")
        fetch(url).then(function (response) {
            console.log(response);
            return response.json();
        }).then(async function (data) {
            setChatResponses([...chatResponses, data.msg])
            let audio_str = 'data:audio/wav;base64,' + data.wavstr;
            let myAudio = new Audio(audio_str);
            setStatus("speaking");
            await playAudio(myAudio)
            setStatus("neutral");
        }).catch(function (e) {
            console.log("Booo", e);
        });
    }

    const createAndSendModel = (event) => {
        const questions = [
            {
                question: "szia",
                answer: "0"
            },
            {
                question: "szióka",
                answer: "0"
            },
            {
                question: "szervusz",
                answer: "0"
            },
            {
                question: "szeva",
                answer: "0"
            },
            {
                question: "csá",
                answer: "0"
            },
            {
                question: "kér",
                answer: "1"
            },
            {
                question: "kérlek",
                answer: "1"
            },
            {
                question: "megkérlek",
                answer: "1"
            },
            {
                question: "megkérnélek",
                answer: "1"
            }
        ];

        let data = [];

        // Setting up the data.
        for (let index in questions) {
            if (questions.hasOwnProperty(index)) {
                data.push({ input: questions[index].question, output: questions[index].answer });
            }
        }

        // Setting up the net
        const net = new window.brain.recurrent.LSTM();
        // Training the net
        net.train(data);
        const networkState = net.toJSON();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            // body: JSON.stringify({ title: 'React POST Request Example' })
            body: JSON.stringify(networkState)
        };
        fetch(global.config.url.speakbot.api, requestOptions)
            .then(response => response.json())
            .then(data => console.log(data.title));
    }

    const brainTrain = (event) => {
        // const likely = brain.likely;

        // THIS DATA WILL BE IMPORTED
        const questions = [
            {
                question: "Hogy vagy?",
                answer: "köszönöm szépen jól"
            },
            {
                question: "Mi a helyzet?",
                answer: "Semmi különös. Neked milyen volt a mai napod?"
            }
        ];

        let data = [];

        // Setting up the data.
        for (let index in questions) {
            if (questions.hasOwnProperty(index)) {
                data.push({ input: questions[index].question, output: questions[index].answer });
            }
        }

        // Setting up the net
        const net = new window.brain.recurrent.LSTM();
        // Training the net
        net.train(data);
        const networkState = net.toJSON();
        console.log("NETWORK STATE: ", networkState);
        // Output
        const output = net.run("Mizu, hogy vagy?");
        console.log(output);
    }

    const langClickAction = (event) => {
        setLangToggle(!langToggle);
    }

    return (
        <>
            <div className="darkBG" id="container" style={{ paddingLeft: "50px", paddingTop: "20px", paddingRight: "50px", paddingBottom: "20px" }} >
                <div style={{width: "75px", height: "37px", margin: "0 auto"}} id="lang" onClick={() => (langClickAction())} >
                    {!langToggle && (<img alt='Loading...' src={huFlag} />)}
                    {langToggle && (<img alt='Loading...' src={enFlag} />)}
                </div>
                <div id="bot" className="neutral" onMouseOver={() => (mouseOverAction())} onMouseLeave={() => (setStatus("neutral"))}>
                    <div id="head">
                        <div id="left-ear">
                            <div id="left-ear-inner"></div>
                        </div>
                        <div id="face">
                            <div id="eyes">
                                <div id="left-eye"></div>
                                <div id="right-eye"></div>
                            </div>
                            <div id="mouth"></div>
                        </div>
                        <div id="right-ear">
                            <div id="right-ear-inner"></div>
                        </div>
                    </div>
                </div>
                <div id="chat-script">
                    <ul style={{ width: "50%", display: "inline-block" }}>
                        {chatTexts && chatTexts.map((chatTextsElem) => {
                            return (
                                <li style={{ color: 'white' }} key={chatTextsElem + Math.random()} >
                                    <span style={{ position: 'relative', top: '5px', margin: '0px 10px 10px 10px' }}><svg xmlns="http://www.w3.org/2000/svg" width="12pt" height="12pt" viewBox="0 0 12 12" version="1.1">
                                        <g id="surface1">
                                            <path style={{ stroke: 'none', fillRule: 'nonzero', fill: 'rgb(53, 84, 212)', fillOpacity: 1 }} d="M 5.976562 11.953125 C 9.277344 11.953125 11.953125 9.277344 11.953125 5.976562 C 11.953125 2.675781 9.277344 0 5.976562 0 C 2.675781 0 0 2.675781 0 5.976562 C 0 9.277344 2.675781 11.953125 5.976562 11.953125 Z M 5.976562 0.933594 C 8.761719 0.933594 11.019531 3.191406 11.019531 5.976562 C 11.019531 8.761719 8.761719 11.019531 5.976562 11.019531 C 3.191406 11.019531 0.933594 8.761719 0.933594 5.976562 C 0.933594 3.191406 3.191406 0.933594 5.976562 0.933594 Z M 5.976562 0.933594 " />
                                            <path style={{ stroke: 'none', fillRule: 'nonzero', fill: 'rgb(53, 84, 212)', fillOpacity: 1 }} d="M 6.351562 8.21875 L 8.964844 5.976562 L 6.351562 3.734375 L 6.351562 4.855469 L 2.988281 4.855469 L 2.988281 7.097656 L 6.351562 7.097656 Z M 6.351562 8.21875 " />
                                        </g>
                                    </svg></span>
                                    {chatTextsElem}
                                </li>
                            )
                        })}
                    </ul>
                    <ul style={{ width: "50%", display: "inline-block" }}>
                        {chatResponses && chatResponses.map((chatResponseElem) => {
                            return (
                                <li style={{ color: 'white' }} key={chatResponseElem + Math.random()} >
                                    <span style={{ position: 'relative', top: '5px', margin: '0px 10px 10px 10px' }}><svg xmlns="http://www.w3.org/2000/svg" width="12pt" height="12pt" viewBox="0 0 12 12" version="1.1">
                                        <g id="surface1">
                                            <path style={{ stroke: 'none', fillRule: 'nonzero', fill: 'rgb(212, 84, 53)', fillOpacity: 1 }} d="M 5.976562 11.953125 C 9.277344 11.953125 11.953125 9.277344 11.953125 5.976562 C 11.953125 2.675781 9.277344 0 5.976562 0 C 2.675781 0 0 2.675781 0 5.976562 C 0 9.277344 2.675781 11.953125 5.976562 11.953125 Z M 5.976562 0.933594 C 8.761719 0.933594 11.019531 3.191406 11.019531 5.976562 C 11.019531 8.761719 8.761719 11.019531 5.976562 11.019531 C 3.191406 11.019531 0.933594 8.761719 0.933594 5.976562 C 0.933594 3.191406 3.191406 0.933594 5.976562 0.933594 Z M 5.976562 0.933594 " />
                                            <path style={{ stroke: 'none', fillRule: 'nonzero', fill: 'rgb(212, 84, 53)', fillOpacity: 1 }} d="M 6.351562 8.21875 L 8.964844 5.976562 L 6.351562 3.734375 L 6.351562 4.855469 L 2.988281 4.855469 L 2.988281 7.097656 L 6.351562 7.097656 Z M 6.351562 8.21875 " />
                                        </g>
                                    </svg></span>
                                    {/* {chatResponseElem} */}
                                    <CodeText code={chatResponseElem} />
                                </li>
                            )
                        })}
                    </ul>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="input-field">
                        {/* <label className="font_8" htmlFor="chat">Type in your chat message, or hover over the head and he will start listening.</label> */}
                        <span onMouseOver={() => (handleColor("lightgreen"))} onMouseLeave={() => (handleColor("#dddddd"))} onClick={() => (handleCopy())}><input placeholder="#Ide jön a szöveg amit hallok." style={{ color: "#dddddd" }} autoComplete="off" className="font_8" type="text" id="interimText" value={interim} disabled /></span>
                        <input style={{ width: "50%", color: "#ffffff" }} autoComplete="off" className="font_8" type="text" id="chatmsg" onChange={handleChange} value={message} />
                        <button style={{ backgroundColor: "#241CED" }} className="btn lighten 1 z-depth-0">Send</button>
                    </div>
                </form>
                {/* <button onClick={() => (modelResponse())} style={{ backgroundColor: "#241CED" }} className="btn lighten 1 z-depth-0">brainjs</button> */}
            </div>
        </>
    )
}

// export default ChatbotFn

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile,
        loading: state.auth.loading,
        users: state.firestore.ordered.users,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default compose(connect(mapStateToProps, mapDispatchToProps), firestoreConnect([{ collection: 'users' }]))(ChatbotFn)


export const createEvent = (event) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        dispatch({type: 'EVENT_LOADING'});
        //make async call to db
        const firestore = getFirestore();
        //const profile = getState().firebase.profile;
        const authorId = getState().firebase.auth.uid;
        firestore.collection('events').add({
            ...event,
            authorId: authorId,
            createdAt: new Date()
        }).then(() => {
            dispatch({ type: 'CREATE_EVENT', event: event });
        }).catch((err) => {
            dispatch({type: 'CREATE_EVENT_ERROR', err});
        })
        
    }
};
export default global.config = {
    i18n: {
        welcome: {
            en: "Welcome",
            fa: "خوش آمدید"
        }
        // rest of your translation object
    },
    url: {
        cors: {
            // proxy: "https://cors-anywhere.herokuapp.com/"
            proxy: "https://proxy.cors.sh/"
            // proxy: "https://asdasd/"
        },
        speakbot: {
            api: "http://192.168.10.105:8080/api",
            prod: "https://esh-mqtt-0.esh.hu/?speech=",
            dev: "http://192.168.10.106:8080/?speech=",
            devtest: "http://192.168.10.105:8080/speech?speech="
            // dev: "https://esh-mqtt-0.esh.hu/speech?speech="
        }
    }
    // other global config variables you wish
};
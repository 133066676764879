import React from 'react';
import '../config';
import {signIn} from "../store/actions/authActions";
import {signUp} from "../store/actions/authActions";
import {signOut} from "../store/actions/authActions";
import '../App.css';
import logo from '../logo.svg';
import UpcommingEvents from "../ComponentBoxes/UpcommingEvents"
import SpotlightMasters from "../ComponentBoxes/SpotlightMasters"
import LatestMembers from "../ComponentBoxes/LatestMembers"
import SmallBox from "../ComponentBoxes/SmallBox"
import LatestNewsImg from "../ComponentBoxes/LatestNewsImg"
import MiddleBox from "../ComponentBoxes/MiddleBox"
import EservantOfMonth from "../ComponentBoxes/EservantOfTheMonth"
import LatestNews from "../ComponentBoxes/LatestNews"
import WelcomeMessage from "../ComponentBoxes/Components/WelcomeMessage"
import { connect } from "react-redux";
import {compose} from "redux";
import { firestoreConnect } from 'react-redux-firebase';
import moment from "moment";
import SocMediaLogin from "../ComponentBoxes/Components/Forms/SocMediaLogin";
import LoginForm from "../ComponentBoxes/Components/Forms/LoginForm";
import RegisterForm from "../ComponentBoxes/Components/Forms/RegisterForm";
import RegisterEventForm from "../ComponentBoxes/Components/Forms/RegisterEventForm";
import EditProfile from "../ComponentBoxes/Components/Forms/EditProfile";
import { Link } from "react-router-dom";

class Home extends React.Component {

    /*constructor() {
        super()
        this.state = {
        }
    }*/
    state = {
        email: '',
        password: '',
        smsBalance: 0,
        smsCredit: 0,
    }

    handleChange = (event) => {
        this.setState({
            [event.target.id]: event.target.value
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        console.log('SIGNING IN...');
        if(this.props.login) this.props.signIn(this.state);
        if(this.props.register) this.props.signUp(this.state);
        //setTimeout(() => {this.props.history.push('/home')},1000);
        this.props.history.push('/home');
    }

    componentDidMount() {
        this.setState({callOnce: true});
        /*const { profile } = this.props;
        console.log("PROFILE: ", profile);
        if(profile.smsKey){
            const proxyurl = global.config.url.cors.proxy;
            fetch(proxyurl + 'http://seeme.hu/gateway?key=' + profile.smsKey + '&method=balance')
                .then(response => response.json())
                .then(smsBalance => {
                    const money = parseFloat(smsBalance.message);
                    const smsCredit = Math.floor(money / 14);
                    return this.setState({ smsBalance, smsCredit: smsCredit });
                })
                .catch(() => console.log("Can’t access " + proxyurl + " response. Blocked by browser?"));
        }else{
            console.log("NO PROFILE DATA...");
            return null;
        }*/
      }

    getSMSBalance = (smsKey) => {
        if(smsKey && this.state.smsCredit === 0){
            const proxyurl = global.config.url.cors.proxy;
            fetch(proxyurl + 'http://seeme.hu/gateway?key=' + smsKey + '&method=balance')
                .then(response => response.json())
                .then(smsBalance => {
                    const money = parseFloat(smsBalance.message);
                    const smsCredit = Math.floor(money / 14);
                    console.log("SMS API CALLED AND FETCHED !!!");
                    return this.setState({ smsBalance, smsCredit: smsCredit });
                })
                .catch(() => console.log("Can’t access " + proxyurl + " response. Blocked by browser?"));
        }else{
            console.log("NO NEED TO API CALL...");
            return null;
        }
    }

    deleteFiles = () => {
        // alert("func called");
        window.fetch('https://esh-mqtt-0.esh.hu/deletefiles', {
            method: 'DELETE'
        })
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(error => console.error(error))
    }

    render() {
        const { auth, profile, users, events, switchKey } = this.props;
        // console.log("SMS KEY: ",profile.smsKey);
        let eventsArr = [{description: "Fake event 1, an event that is totally fake!!!", end: 9999999999, id: "Fake1", location: "Budapest", name: "Fake Event 1", start: 9999999999 }, {description: "Fake event 2, an event that is totally fake!!!", end: 9999999999, id: "Fake2", location: "Budapest", name: "Fake Event 2", start: 9999999999 }, {description: "Fake event 3, an event that is totally fake!!!", end: 9999999999, id: "Fake3", location: "Budapest", name: "Fake Event 3", start: 9999999999 }, {description: "Fake event 4, an event that is totally fake!!!", end: 9999999999, id: "Fake4", location: "Budapest", name: "Fake Event 4", start: 9999999999 }];
        if(auth.uid) {
            //set up events to show
            events ? 
            eventsArr = events.filter(( event ) => {
                return event.start >= moment().unix();
            })
            :eventsArr = [];
            if(this.state.smsCredit === 0) this.getSMSBalance(profile.smsKey);
        }
        
        let welcomeMSG = "";

        switch (switchKey) {
            case "socmedialogin":
                welcomeMSG = (<div className="container font_8" ><SocMediaLogin /></div>)
                break;
            case "login":
                welcomeMSG = (<div className="container"><LoginForm /></div>)
                break;
            case "register":
                welcomeMSG = (<div className="container"><RegisterForm /></div>)
                break;
            case "loading":
                welcomeMSG = <img style={{width: "200px", height: "200px", marginLeft: "90px", }} src={require('../images/e-servant_loading6.gif')} alt="loading..." />;
                break;
            case "editprofile":
                welcomeMSG = (<div className="container"><EditProfile /></div>)
                break;
            case "registerEvent":
                welcomeMSG = (<div className="container"><RegisterEventForm /></div>)
                break;
            default:
                //if(auth.uid) console.log(auth);
                const editProfileLink = <Link to="/editprofile"><p className="font_LB whitehover" >here</p></Link>
                const chatbotLink = <Link to="/chatbot"><p className="font_LB whitehover" >here</p></Link>
                const smsFarmingLink = <Link to="/smsfarming"><p className="font_LB whitehover" >here</p></Link>
                welcomeMSG = auth.uid ? 
                                <><h5 style={{paddingLeft: "20px"}} className="font_BigBlue">Welcome <span className="font_8">{profile.firstName?profile.firstName:auth.displayName?auth.displayName:"Default Username"} </span>!<img alt="Loading..." style={{border: "2px solid white", float: "right", width: "60px", height: "60px"}} src={auth.photoURL?auth.photoURL:profile.profilePic}></img></h5>
                                <div className="container"><p className="font_8">Available SMS Credit: {this.state.smsCredit}</p><p className="font_8">Ongoing Projects: 0</p> <p className="font_8">Survived Events: 0</p> </div>
                                <div style={{display: "flex"}} className="font_8">{profile.firstName?"You can edit your profile ":auth.displayName?"You can edit your profile ":"Your user has no real username, please edit it "} &nbsp; {editProfileLink} , </div>
                                <div className="font_8" style={{display: "flex"}}>You can also chat with your e-servant &nbsp; {chatbotLink}, </div>
                                <div style={{display: "flex"}} className="font_8">To get SMS credits, check &nbsp; {smsFarmingLink},</div>
                                <div style={{display: "flex"}} className="font_8">You can also logout &nbsp;
                                <a style={{position: 'relative', left: '75px', cursor: 'pointer'}} className="font_LB centered whitehover" onClick={this.props.signOut}>here</a></div>
                                { auth.uid && auth.uid == "eB4FzlwLFnRhq3nJfwtBj1Bsfz62" ? <div style={{display: "flex"}} className="font_8">Delete all chat files &nbsp; <a style={{position: 'relative', left: '75px', cursor: 'pointer'}} className="font_LB centered whitehover" onClick={this.deleteFiles}>here</a></div> : <></> }
                                </>
                                : <WelcomeMessage/>;
                break;
        }

        return (
            <div id="SITE_ROOT" className="SITE_ROOT" >
                <div className="left_side_container">
                    <img alt='Loading...' src={logo} /><span className="font_10">LATEST MEMBERS_</span>
                    <LatestMembers users={users}/>
                      <SmallBox />
                    <LatestNewsImg />
                </div>
                <div className="middle_side_container" >
                    <MiddleBox>{welcomeMSG}</MiddleBox>
                    <EservantOfMonth />
                    <LatestNews events={eventsArr}/>
                </div>
                <div className="right_side_container">                        
                    <img src={logo} alt='Loading...' /><span className="font_10">UPCOMING EVENTS_</span>
                    <UpcommingEvents events={eventsArr}/>
                    <img src={logo} alt='Loading...' /><span className="font_10">SPOTLIGHT MEMBERS_</span>
                    <SpotlightMasters users={users}/>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.authError,
        auth: state.firebase.auth,
        profile: state.firebase.profile,
        loading: state.auth.loading,

        users: state.firestore.ordered.users,
        events: state.firestore.ordered.events,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        signIn: (creds) => dispatch(signIn(creds)),
        signUp: (newUser) => dispatch(signUp(newUser)),
        signOut: () => dispatch(signOut())
    }
}

export default compose( connect(mapStateToProps, mapDispatchToProps), firestoreConnect([{collection:'users'}, {collection:'events'}]) )(Home)

import moment from "moment";

export const signIn = (credentials) => {
    return (dispatch, getState, { getFirebase }) => {
        dispatch({type: 'LOADING'});
        const firebase = getFirebase();

        firebase.auth().signInWithEmailAndPassword(
            credentials.email,
            credentials.password
        ).then(() => {
            dispatch({ type: 'LOGIN_SUCCESS' })
        }).catch((err) => {
            dispatch({ type: 'LOGIN_ERROR', err })
        });

    }
}

export const signOut = () => {
    return (dispatch, getState, {getFirebase}) => {
        const firebase = getFirebase();

        firebase.auth().signOut().then(() => {
            dispatch({type: 'SIGNOUT_SUCCESS'});
        })
    }
}

export const signUp = (newUser) => {
    return (dispatch, getState, {getFirebase, getFirestore }) => {
        const Firebase = getFirebase();
        const Firestore = getFirestore();
        const d = new Date();
        // console.log("secret code: ", d.getDate());

        if (newUser && newUser.secCode == d.getDate()) {
            Firebase.auth().createUserWithEmailAndPassword(
                newUser.email,
                newUser.password
            ).then((resp) => {
                return Firestore.collection('users').doc(resp.user.uid).set({
                    firstName: newUser.firstName,
                    lastName: newUser.lastName,
                    initials: newUser.firstName[0] + newUser.lastName[0],
                    joinedAt: moment().unix()
                })
            }).then(() => {
                dispatch({type: 'SIGNUP_SUCCESS'})
            }).catch((err) => {
                dispatch({type: 'SIGNUP_ERROR', err})
            })
        }
        else {
            const err = "Fuck off you damn bot!";
            dispatch({type: 'SIGNUP_ERROR', err})
        }
    }
}

export const editProfile = (userData) => {
    return (dispatch, getState, {getFirebase, getFirestore }) => {
        dispatch({type: 'LOADING'});
        const Firebase = getFirebase();
        const Firestore = getFirestore();
        const joined = (userData.createdAt-(userData.createdAt%1000))/1000;

        let userDataObjectToSet = {};
        const fn = userData.firstName?{firstName: userData.firstName}:null;
        if(fn != null){Object.assign(userDataObjectToSet, fn);}
        const ln = userData.lastName?{lastName: userData.lastName}:null;
        if(ln != null){Object.assign(userDataObjectToSet, ln);}

        const pn = userData.phoneNumber?{phoneNumber: userData.phoneNumber}:null;
        if(pn != null){Object.assign(userDataObjectToSet, pn);}
        const sk = userData.smsKey?{smsKey: userData.smsKey}:null;
        if(sk != null){Object.assign(userDataObjectToSet, sk);}

        let ini = null;
        if(fn != null && ln != null) {ini = fn[0] + ln[0];
        Object.assign(userDataObjectToSet, ini);}
        const ja = joined?{joinedAt: joined}:null;
        if(ja != null){Object.assign(userDataObjectToSet, ja);}
        const pp = userData.photoURL?{profilePic: userData.photoURL}:null;
        if(pp != null){Object.assign(userDataObjectToSet, pp);}
        console.log("USER OBJECT DATA TO SET: ", userDataObjectToSet);

        

        return Firestore.collection('users').doc(userData.uid).update(userDataObjectToSet).then(() => {
            dispatch({type: 'EDITPROFILE_SUCCESS'})
        }).catch((err) => {
            console.log(err);
            Firestore.collection('users').doc(userData.uid).set(userDataObjectToSet).then(() => {
                dispatch({type: 'EDITPROFILE_SUCCESS'})
            }).catch((err) => {
                dispatch({type: 'EDITPROFILE_ERROR', err})
            })
        })
    }
}
import React from "react"

const WelcomeMessage = () => (
    <div>
        <div style={{fontSize: "2.5vw", paddingTop:"12px", position:"relative", margin: "10px", left: "7%"}} className="font_BigBlue">WELCOME!</div>
        <div className="rowSptlght">
            <div className="font_SimpleBlue" style={{textAlign:"center", padding:"10px", borderRight: "1px solid", borderColor: "white"}} >OUR MISSION</div>
            <div className="font_8" style={{padding:"10px"}}>The purpose of this site is to experiment with machine learning, Artificial Intelligence, and IoT devices. If you want to build your own electronic assistant (<span style={{color: "#20B2AA"}} >e-servant</span>), then you are at the right place.</div>
        </div>
    </div>
)

export default WelcomeMessage
import React from 'react';
import logoT from './logoWithText.svg';
import './App.css';
import { BrowserRouter, Switch, Route, Link } from "react-router-dom";
import Banner from "./ComponentBoxes/Banner"
import NavBar from "./ComponentBoxes/NavBar"
import Home from "./Sites/Home";
import ProjectHome from "./Sites/ProjectHome";
import Community from "./Sites/Community";
import ActivityDiary from "./Sites/ActivityDiary";
import Privacy from './Sites/Privacy';
import TermsOfService from './Sites/TermsOfService';
import ChatbotFn from './Sites/ChatbotFn';

function App()  {
    return (
      <BrowserRouter>
          <div id="SITE_STRUCTURE" className="SITE_STRUCTURE">
            <div className="SITE_TOP">
            <Banner />
            <Link to="/home"><img src={logoT} className="Applogo" alt="logo" /></Link>
            </div>
            <NavBar />
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/home" component={Home} />
    {/*HOME-MIDDLEBOX related routes START*/}
              <Route path="/socmedialogin" render={(props) => {return (<Home {...props} switchKey={"socmedialogin"} />)}} />
              <Route path="/signin" render={(props) => {return (<Home {...props} switchKey={"login"} />)}} />
              <Route path="/signup" render={(props) => {return (<Home {...props} switchKey={"register"} />)}} />
              <Route path="/editprofile" render={(props) => {return (<Home {...props} switchKey={"editprofile"} />)}} />
              <Route path="/regevent" render={(props) => {return (<Home {...props} switchKey={"registerEvent"} />)}} />
    {/*HOME-MIDDLEBOX related routes END*/}

              <Route path="/privacy" component={Privacy} />

    {/*PROJECT related routes START*/}
              <Route path="/projects" component={ProjectHome} />
    {/*PROJECT related routes END*/}

    {/*CHATBOT related routes START*/}
    <Route path="/chatbot" component={ChatbotFn} />
    {/*CHATBOT related routes END*/}

              <Route path="/termsofservice" component={TermsOfService} />

    {/*COMMUNITY related routes START*/}
              <Route path="/activity" component={ActivityDiary} />
    {/*COMMUNITY related routes END*/}
            </Switch>
          </div>
      </BrowserRouter>
    );
}

export default App;

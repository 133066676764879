import React from 'react';
import { Link } from "react-router-dom";
import {connect} from "react-redux";
import {signOut} from "../../store/actions/authActions";

const SmallBoxSignedInLinks = (props) => {
    return (
        <>
            <Link to="/editprofile"><p className="font_LB centered whitehover" >Edit Profile</p></Link>
            <a style={{position: 'relative', left: '75px', cursor: 'pointer'}} className="font_LB centered whitehover" onClick={props.signOut}>Log out</a>
        </>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut())
    }
}

export default connect(null, mapDispatchToProps)(SmallBoxSignedInLinks)
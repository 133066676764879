import React from "react"

class LatestNewsImg extends React.Component{
    constructor(){
        super()
        this.state = {
            TopTitle: "LATEST NEWS",
            TopImg: require("../images/ExampleEvent.png"),
            BotImg: require("../images/ExampleEvent2.png"),
        }
    }

    render(){
        return (
        <div style={{marginTop: "20px"}}>
            <p className="font_SimpleBlue" >{ this.state.TopTitle }</p>
            <div className="darkBG shinyBox" >
                <img style={{width: "259px", marginBottom: "10px", marginTop: "10px"}} src={this.state.TopImg} alt='Loading...' />
                <div className="e1line"></div>
                <img style={{width: "259px", marginTop: "10px"}} src={this.state.BotImg} alt='Loading...' />
            </div>
        </div>
        )
    }
}

export default LatestNewsImg
import React from "react";
import {signUp} from "../../../store/actions/authActions";
import { connect } from "react-redux";
import {compose} from "redux";
import { firestoreConnect } from 'react-redux-firebase';
import {Redirect} from "react-router-dom";

class RegisterForm extends React.Component {

    state = {
        email: '',
        password: '',
        profilePic: 'https://firebasestorage.googleapis.com/v0/b/e-servant.appspot.com/o/images%2F32808b6e-00d2-4fbd-b22f-f10357bc9262.png?alt=media&token=ff32932d-3246-4ad6-865c-945d45372619',
    }

    handleChange = (event) => {
        this.setState({
            [event.target.id]: event.target.value
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        console.log('SIGNING UP...');
        this.props.signUp(this.state);
    }

    render(){
        const {authError, auth, loading } = this.props;
        if(auth.uid) return <Redirect to='/' />
        if(loading){
            return (<img style={{width: "200px", height: "200px", marginLeft: "38px", }} src={require('../../../images/e-servant_loading6.gif')} alt="loading..." />);
        }else{
            return (
                <form onSubmit={this.handleSubmit}>
                    <h5 style={{ paddingTop: "10px !important"}} className="font_10">SIGN UP_</h5>
                    <div className="input-field">
                        <label className="font_8" htmlFor="email">Email</label>
                        <input autoComplete="off" className="font_8" type="email" id="email" onChange={this.handleChange}/>
                    </div>
                    <div className="input-field">
                        <label className="font_8" htmlFor="password">Password</label>
                        <input className="font_8" type="password" id="password" onChange={this.handleChange}/>
                    </div>
                    <div className="input-field">
                        <label className="font_8" htmlFor="firstName">First Name</label>
                        <input autoComplete="off" className="font_8" type="text" id="firstName" onChange={this.handleChange}/>
                    </div>
                    <div className="input-field">
                        <label className="font_8" htmlFor="lastName">Last Name</label>
                        <input autoComplete="off" className="font_8" type="text" id="lastName" onChange={this.handleChange}/>
                    </div>
                    <div className="input-field">
                        <label className="font_8" htmlFor="secCode">Secret Code:</label>
                        <input className="font_8" type="password" id="secCode" onChange={this.handleChange}/>
                    </div>

                    <div className="input-field">
                        <button style={{backgroundColor: "#241CED"}} className="btn lighten 1 z-depth-0">Sign up</button>
                        <div className="red-text center">
                            {authError?<p>{authError}</p>:null}
                        </div>
                    </div>
                </form>
            )
          }
    }
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.authError,
        auth: state.firebase.auth,
        loading: state.auth.loading,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        signUp: (newUser) => dispatch(signUp(newUser))
    }
}

export default compose( connect(mapStateToProps, mapDispatchToProps), firestoreConnect([{collection:'users'}, {collection:'events'}]) )(RegisterForm)
import React from 'react';
import { NavLink } from "react-router-dom";
import {connect} from "react-redux";
import {signOut} from "../store/actions/authActions";

const SignedInLinks = (props) => {
    //<a className="navbarlink3" onClick={props.signOut}>Log out</a>
    return (
        <>
            <NavLink to="/home" className="navbarlink">Home</NavLink>
            {/* <NavLink to="/projects" className="navbarlink2">Projects</NavLink> */}
            <NavLink to="/chatbot" className="navbarlink2">Chatbot</NavLink>
            <NavLink to="/activity" className="navbarlink3">Activity Diary</NavLink>
        </>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut())
    }
}

export default connect(null, mapDispatchToProps)(SignedInLinks)
import React from "react";

const ProjectSummary = ({project}) => {
    return (
        <div className="z-depth-0" style={{marginBottom: "24px", borderRadius: "0 0 2px 2px", boxSizing: "border-box"}}>
            <div className="card-content darkBG" style={{padding: "24px"}}>
                <h5 className="card-title">
                    {project.title}
                </h5>
                <p>Posted by {project.authorFirstName} {project.authorLastName} </p>
                <p className="grey-text">{project.createdAt.toDate().toString()}</p>
            </div>
        </div>
    )
}

export default ProjectSummary
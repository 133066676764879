import React from "react";
import '../App.css';
import '../config';
import logo from '../logo.svg';
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from 'react-redux-firebase';
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import ShoppingList from "../ComponentBoxes/shoppingListCreate";

import { saveSms } from "../store/actions/experimentalActions";

class Community extends React.Component {
    constructor() {
        super()
        this.state = {}
    }

    handleChange = (event) => {
        const str = event.target.value.replace(/(?:\r\n|\r|\n)/g, '%0a');
        this.setState({
            [event.target.id]: str
        })
    }

    handleSmsSubmit = (e) => {
        e.preventDefault();
        const { auth, users } = this.props;
        const smsObject = this.state;
        if (users) {
            const loggedInUsers = users.filter((user) => { if (user.id === auth.uid) return user; else { return null } });
            const smsKeyObj = { smsKey: loggedInUsers[0].smsKey }
            Object.assign(smsObject, smsKeyObj);
        }
        //console.log('Sending SMS data...', smsObject);
        this.props.saveSms(smsObject);
        this.sendSMS(smsObject);
    }

    sendSMS = (stateObject) => {
        if (stateObject.smsKey && stateObject.smsText && stateObject.phoneNumber) {
            const proxyurl = global.config.url.cors.proxy;
            const smsUrl = "https://seeme.hu/gateway?key=" + stateObject.smsKey + "&message=" + stateObject.smsText.replace(/\s/g, "%20") + "&number=" + stateObject.phoneNumber + "&callback=4,6,7&format=json";
            fetch(proxyurl + smsUrl)
                .then(response => response.json())
                .then(response => {
                    console.log("SMS SENDING SUCCESSFUL !!!", response);
                    //return this.setState({ response });
                })
                .catch(() => console.log("Can’t access SMS sending website... Blocked by browser?"));
        } else {
            console.log("NO NEED TO API CALL...");
            return null;
        }
    }
    getSMSBalance = (smsKey) => {
        if (smsKey && this.state.smsCredit === 0) {
            const proxyurl = global.config.url.cors.proxy;
            fetch(proxyurl + 'http://seeme.hu/gateway?key=' + smsKey + '&method=balance')
                .then(response => response.json())
                .then(smsBalance => {
                    const money = parseFloat(smsBalance.message);
                    const smsCredit = Math.floor(money / 14);
                    console.log("SMS API CALLED AND FETCHED !!!");
                    return this.setState({ smsBalance, smsCredit: smsCredit });
                })
                .catch(() => console.log("Can’t access " + proxyurl + " response. Blocked by browser?"));
        } else {
            console.log("NO NEED TO API CALL...");
            return null;
        }
    }

    render() {
        const { auth, loading } = this.props;
        if (!auth.uid) {
            return <Redirect to='/signin' />
        }
        if (loading) {
            return (<img style={{ width: "200px", height: "200px", marginLeft: "38px", }} src={require('../images/e-servant_loading6.gif')} alt="loading..." />);
        }
        return (
            <>
                <h3 className="darkBG" style={{ padding: "24px", marginBottom: "24px" }}>This page contains some special features...</h3>
                <ShoppingList />
                <form className="darkBG" style={{ padding: "24px" }} onSubmit={this.handleSmsSubmit}>
                    <h5 style={{ paddingTop: "10px !important" }} className="font_10">SENDING SMS_</h5>
                    <div className="input-field">
                        <label className="font_8" htmlFor="phoneNumber">Phone Number to send to:</label>
                        <input autoComplete="off" className="font_8" type="text" id="phoneNumber" onChange={this.handleChange} />
                    </div>
                    <div className="input-field">
                        <label className="font_8" htmlFor="smsText">SMS Text to send:</label>
                        <textarea autoComplete="off" className="font_8" id="smsText" onChange={this.handleChange} />
                    </div>
                    <div className="input-field" style={{ display: "none" }}>
                        <label className="font_8" htmlFor="smsKey">SMS Key</label>
                        <input autoComplete="off" className="font_8" type="text" id="smsKey" onChange={this.handleChange} />
                    </div>

                    <div className="input-field">
                        <button style={{ backgroundColor: "#241CED" }} className="btn lighten 1 z-depth-0">send sms</button>
                    </div>
                </form>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.authError,
        auth: state.firebase.auth,
        profile: state.firebase.profile,
        loading: state.auth.loading,

        users: state.firestore.ordered.users,
        events: state.firestore.ordered.events,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        saveSms: (data) => dispatch(saveSms(data))
    }
}

export default compose(connect(mapStateToProps, mapDispatchToProps), firestoreConnect([{ collection: 'users' }, { collection: 'events' }]))(Community)

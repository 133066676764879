import React from 'react';
import { Link } from "react-router-dom";

const SmallBoxSignedOutLinks = () => {
    return (
        <>
            <Link to="/signin"><p style={{ width: "100%", height: "100%" }} className="font_LB centered whitehover" >Login</p></Link>
            <Link to="/socmedialogin"><p className="font_LB centered whitehover" >Social Media Login</p></Link>
        </>
    )
}

export default SmallBoxSignedOutLinks
import React from "react"

class EservantOfMonth extends React.Component{
    constructor(){
        super()
        this.state = {
            image: require("../images/kivalokiwano_transparent.png"),
            name: "KIWANO KO1"
        }
    }

    render(){
        //width: "246px", height: "134px", borderRadius: "50px 20px", position: "relative", right: "75px", paddingTop: "130px"
        return (
        <div>
            <div className="font_LB_mid" style={{position: "relative", left: "30%", top: "100px", width: "200px",  borderTop: "2px solid #93a7ff"}} > Device Of The Month !</div>
            <svg className="ROTATE" style={{position: "absolute", width: "340px", height: "401px" }} data-original-aspect-ratio="0.8469191749032657" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g >
                    <polygon style={{fill: "rgba(0, 0, 0, 0.3)"}} points="339.614526,300.749371 169.806693,400.999921 0,300.749371 0,100.25055 169.806693,0 339.614526,100.25055"></polygon>
                </g>
            </svg>
            <img style={{width: "180px", height: "240px", position: "relative", left: "36%", top: "100px",}} src={this.state.image} alt='Loading...' />
            <div className="font_8" style={{position: "relative", top: "100px", left: "45%"}} >{this.state.name}</div>
        </div>
        )
    }
}

export default EservantOfMonth
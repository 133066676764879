import React, { Component } from 'react'
import { createProject } from "../../../store/actions/projectActions";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import logo from '../../../logo.svg';


class CreateProject extends Component {
    state = {
        title: '',
        content: '',
        location: '',
        start: '',
        end: ''
    }

    speechRecog = (id) => {
        window.SpeechRecognition = window.webkitSpeechRecognition || window.SpeechRecognition;
        let finalTranscript = '';
        try {
            let recognition = new window.SpeechRecognition();
            recognition.interimResults = true;
            recognition.maxAlternatives = 10;
            recognition.continuous = true;
            recognition.onresult = (event) => {
                let interimTranscript = '';
                for (let i = event.resultIndex, len = event.results.length; i < len; i++) {
                    let transcript = event.results[i][0].transcript;
                    if (event.results[i].isFinal) {
                        finalTranscript = transcript;
                    } else {
                        interimTranscript += transcript;
                    }
                    console.log("INTERIM TRANSCRIPT: ", interimTranscript);
                    console.log("FINAL TRANSCRIPT: ", finalTranscript);
                }
                let inputTODO = document.getElementById(id);
                inputTODO.value = finalTranscript;// + interimTranscript;
                this.setState({
                    [id]: finalTranscript
                })
            }
            recognition.onspeechend = () => {
                recognition.stop();
                let inputTODO = document.getElementById(id);
                if(inputTODO) inputTODO.value = finalTranscript;// + interimTranscript;
                this.setState({
                    [id]: finalTranscript
                })
            }
            recognition.start();
        } catch (e) {
            console.log(e);
        }
        console.log(finalTranscript);
        return finalTranscript;
    }

    handleChange = (project) => {
        this.setState({
            [project.target.id]: project.target.value
        })
    }

    handleStartDateChange = (date) => {
        this.setState({
            start: date,
        })
    }

    handleEndDateChange = (date) => {
        this.setState({
            end: date,
        })
        //console.log(moment(date).unix())
    }

    handleSave = () => {
        console.log('saved', this.state.end.unix())
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.createProject({...this.state, start: moment(this.state.start).unix(), end: moment(this.state.end).unix(), })
    }

    render() {
        const { auth, loading } = this.props;
        if (!auth.uid) { return <Redirect to='/signin' /> };
        if (loading) {
            return (<img style={{ width: "200px", height: "200px", marginLeft: "38px", }} src={require('../../../images/e-servant_loading6.gif')} alt="loading..." />);
        } else {
            return (
                <form onSubmit={this.handleSubmit} style={{ width: "50%", display: "inline-block", paddingTop: "20px", verticalAlign: "top"}} >
                    <img alt='Loading...' src={logo} /><span className="font_10">ÚJ TEVÉKENYSÉG_</span>
                    <div className="input-field">
                        <label htmlFor="title">Tevékenység neve</label>
                        <input onClick={() => this.speechRecog("title")} className="font_8" autoComplete="off" type="text" id="title" onChange={this.handleChange} />
                    </div>
                    <div className="input-field">
                        <label htmlFor="content">Tevékenység leírása</label>
                        <textarea onClick={() => this.speechRecog("content")} name="content" id="content" onChange={this.handleChange} className="font_8 materialize-textarea"></textarea>
                    </div>
                    <div className="input-field">
                        <label htmlFor="location">Tevékenység helye</label>
                        <input onClick={() => this.speechRecog("location")} className="font_8" autoComplete="off" type="text" id="location" onChange={this.handleChange} />
                    </div>
                    <div style={{ display: "flex" }}>
                        <div style={{ width: "25%", paddingTop: "20px" }}>
                        <label htmlFor="start">Kezdés időpontja</label>
                        </div>
                        <div style={{ marginLeft: '18px' }}></div>
                        <DatePicker
                            className="font_8"
                            autoComplete="off"
                            selected={this.state.start}
                            onChange={this.handleStartDateChange}
                            id="start"
                            value={this.state.start}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            dateFormat="MMMM d, yyyy h:mm aa"
                            timeCaption="time"
                            minDate={new Date()}
                        />
                    </div>
                    <div style={{ display: "flex" }}>
                        <div style={{ width: "25%", paddingTop: "20px" }}>
                        <label htmlFor="end">Befejezés időpontja</label>
                        </div>
                        <div style={{ marginLeft: '18px' }}></div>
                        <DatePicker
                            className="font_8"
                            autoComplete="off"
                            selected={this.state.end}
                            onChange={this.handleEndDateChange}
                            id="end"
                            value={this.state.end}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            dateFormat="MMMM d, yyyy h:mm aa"
                            timeCaption="time"
                            minDate={new Date()}
                        />
                    </div>



                    <div className="input-field">
                        <button style={{ backgroundColor: "#241CED" }} className="btn lighten 1 z-depth-0">Hozzáad</button>
                    </div>
                </form>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        loading: state.project.loading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createProject: (project) => dispatch(createProject(project))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateProject)

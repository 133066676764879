import React, { Component } from 'react'
import { createEvent } from "../../../store/actions/eventActions";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


class CreateEvent extends Component {
    state = {
        name: '',
        description: '',
        location: '',
        start: '',
        end: '',
    }

    handleChange = (event) => {
        this.setState({
            [event.target.id]: event.target.value
        })
    }

    handleStartDateChange = (date) => {
        this.setState({
            start: date,
        })
    }

    handleEndDateChange = (date) => {
        this.setState({
            end: date,
        })
        //console.log(moment(date).unix())
    }

    handleSave = () => {
        console.log('saved', this.state.end.unix())
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.createEvent({...this.state, start: moment(this.state.start).unix(), end: moment(this.state.end).unix(), })
    }

    render() {
        const { auth, loading } = this.props;
        if (!auth.uid) { return <Redirect to='/signin' /> };
        if (loading) {
            return (<img style={{ width: "200px", height: "200px", marginLeft: "38px", }} src={require('../../../images/e-servant_loading6.gif')} alt="loading..." />);
        } else {
            return (
                <form onSubmit={this.handleSubmit} >
                    <h5 className="font_BigBlue">Create New Event</h5>
                    <div className="input-field">
                        <label htmlFor="name">Title</label>
                        <input className="font_8" autoComplete="off" type="text" id="name" onChange={this.handleChange} />
                    </div>
                    <div className="input-field">
                        <label htmlFor="description">Event Description</label>
                        <textarea name="description" id="description" onChange={this.handleChange} className="font_8 materialize-textarea"></textarea>
                    </div>
                    <div className="input-field">
                        <label htmlFor="location">Location</label>
                        <input className="font_8" autoComplete="off" type="text" id="location" onChange={this.handleChange} />
                    </div>
                    <div style={{ display: "flex" }}>
                        <label htmlFor="start">Event Start_</label>
                        <div style={{ marginLeft: '18px' }}></div>
                        <DatePicker
                            className="font_8"
                            autoComplete="off"
                            selected={this.state.start}
                            onChange={this.handleStartDateChange}
                            id="start"
                            value={this.state.start}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            dateFormat="MMMM d, yyyy h:mm aa"
                            timeCaption="time"
                            minDate={new Date()}
                        />
                    </div>
                    <div style={{ display: "flex" }}>
                        <label htmlFor="end">Event End_</label>
                        <div style={{ marginLeft: '22px' }}></div>
                        <DatePicker
                            className="font_8"
                            autoComplete="off"
                            selected={this.state.end}
                            onChange={this.handleEndDateChange}
                            id="end"
                            value={this.state.end}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            dateFormat="MMMM d, yyyy h:mm aa"
                            timeCaption="time"
                            minDate={new Date()}
                        />
                    </div>



                    <div className="input-field">
                        <button style={{ backgroundColor: "#241CED" }} className="btn lighten 1 z-depth-0">Create</button>
                    </div>
                </form>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        loading: state.event.loading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createEvent: (event) => dispatch(createEvent(event))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateEvent)

import React from "react";
import { connect } from "react-redux";
import SmallBoxSignedInLinks from './Components/SmallBoxSignedInLinks';
import SmallBoxSignedOutLinks from './Components/SmallBoxSignedOutLinks'

const SmallBox = (props) => {
    const { auth, profile } = props;
    const links = auth.uid ? <SmallBoxSignedInLinks profile={profile} /> : <SmallBoxSignedOutLinks/>;
    return (
        <div className="darkBG" >
            <div className="smallbox" >
            { links }
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    //console.log(state);
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}
export default connect(mapStateToProps)(SmallBox)
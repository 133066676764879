import React from "react";
import {signIn} from "../../../store/actions/authActions";
import { connect } from "react-redux";
import {compose} from "redux";
import { firestoreConnect } from 'react-redux-firebase';
import {Redirect, Link} from "react-router-dom";

class LoginForm extends React.Component {

    state = {
        email: '',
        password: '',
    }

    handleChange = (event) => {
        this.setState({
            [event.target.id]: event.target.value
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        console.log('SIGNING IN...');
        this.props.signIn(this.state);
    }

    render(){
        const {authError, auth, loading } = this.props;
        //const asdf = new auth.FacebookAuthProvider();
        //console.log(asdf);
        if(auth.uid) return <Redirect to='/' />
        if(loading){
            return (<img style={{width: "200px", height: "200px", marginLeft: "38px", }} src={require('../../../images/e-servant_loading6.gif')} alt="loading..." />);
        }else{
            return (
                <form onSubmit={this.handleSubmit} >
                    <h5 style={{ paddingTop: "10px !important" }} className="font_10">SIGN IN_</h5>
                    <div className="input-field">
                        <label className="font_8" style={{ padding: "10px" }} htmlFor="email">Email</label>
                        <input className="font_8" autoComplete="off" type="email" id="email" onChange={this.handleChange} />
                    </div>
                    <div className="input-field">
                        <label className="font_8" style={{ padding: "10px" }} htmlFor="password">Password</label>
                        <input className="font_8" type="password" id="password" onChange={this.handleChange} />
                    </div>
                    <div className="font_8" style={{ padding: "10px" }} >You can also login using <Link to="/socmedialogin">social media</Link>, or <Link to="/signup">register here</Link>.</div>
            
                    <div className="input-field">
                        <button style={{ backgroundColor: "#241CED" }} className="btn lighten 1 z-depth-0">Login</button>
                        <div className="red-text center">
                            {authError ? <p>{authError}</p> : null}
                        </div>
                    </div>
                </form>
            )
          }
    }
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.authError,
        auth: state.firebase.auth,
        loading: state.auth.loading,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        signIn: (creds) => dispatch(signIn(creds)),
    }
}

export default compose( connect(mapStateToProps, mapDispatchToProps), firestoreConnect([{collection:'users'}]) )(LoginForm)
import React from "react";
import ProjectSummary from "./Components/ProjectSummary";
import {Link} from "react-router-dom";
import logo from '../logo.svg';

const ProjectList = ({projects}) => {

    return (
        <div>
            {/* <div className="project-list section"> */}
                <img alt='Loading...' src={logo} /><span className="font_10">LEGUTÓBBI TEVÉKENYSÉGEK_</span>
                { projects && projects.map((project) => {
                    return (
                        <Link key={project.id} to={'/project/'+project.id}><ProjectSummary project={project}  /></Link>
                    )
                }) }
            {/* </div> */}
        </div>
    )
}

export default ProjectList
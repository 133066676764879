import React from "react"
import {Link} from "react-router-dom";

const MiddleButton = (props) => (
    <Link to={props.linkTo}>
        <div className="darkBG" style={{cursor: 'pointer', padding: "10px", border: "solid rgba(3, 3, 139, 1) 2px"}} >
            <h1 className="font_LB_big" style={{fontWeight: "bold", position:"relative", left: "36%"}} >{props.btnText?props.btnText:'Join Us Now!'}</h1>
        </div>
    </Link>
)

export default MiddleButton
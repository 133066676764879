import authReducer from "./authReducer";
import projectReducer from "./projectReducer";
import eventReducer from "./eventReducer";
import {combineReducers} from "redux";
import {firestoreReducer} from "redux-firestore";
import {firebaseReducer} from "react-redux-firebase";
import experimentalReducer from "./experimentalReducer";

const rootReducer = combineReducers({
    auth: authReducer,
    project: projectReducer,
    event: eventReducer,
    experimental: experimentalReducer,
    firestore: firestoreReducer,
    firebase: firebaseReducer
})

export default rootReducer
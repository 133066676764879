import React from "react";
import {signIn} from "../../../store/actions/authActions";
import { connect } from "react-redux";
import {compose} from "redux";
import { firestoreConnect } from 'react-redux-firebase';
import {Redirect} from "react-router-dom";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth"

class SocMediaLogin extends React.Component {

    render(){
        const { authError, auth, loading, firebase } = this.props;
        console.log(firebase.auth.PhoneAuthProvider.PROVIDER_ID)
        //return (<>asd</>);
        let uiConfig = {
            signInFlow: "popup",
            signInOptions: [
                firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                firebase.auth.FacebookAuthProvider.PROVIDER_ID,
                firebase.auth.GithubAuthProvider.PROVIDER_ID,
                firebase.auth.PhoneAuthProvider.PROVIDER_ID,
            ],
            callbacks: {
                signInSuccessWithAuthResult: () => false
              }
          }
        //const asdf = new auth.FacebookAuthProvider();
        //console.log(asdf);
        if(auth.uid) return <Redirect to='/' />
        if(loading){
            return (<img style={{width: "200px", height: "200px", marginLeft: "38px", }} src={require('../../../images/e-servant_loading6.gif')} alt="loading..." />);
        }else{
            return (
                <StyledFirebaseAuth
                    uiConfig={uiConfig}
                    firebaseAuth={firebase.auth()}
                />
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.authError,
        auth: state.firebase.auth,
        loading: state.auth.loading,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        signIn: (creds) => dispatch(signIn(creds)),
    }
}

export default compose( connect(mapStateToProps, mapDispatchToProps), firestoreConnect([{collection:'users'}]) )(SocMediaLogin)
import firebase from "firebase/app";
import 'firebase/firestore'
import 'firebase/auth'

// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyCEfLnmWCvAhM4OB-V-v9Wrig_wTkCXycQ",
    authDomain: "e-servant.firebaseapp.com",
    databaseURL: "https://e-servant.firebaseio.com",
    projectId: "e-servant",
    storageBucket: "e-servant.appspot.com",
    messagingSenderId: "775629124927",
    appId: "1:775629124927:web:8356cda3166e5e71"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  firebase.firestore().settings({})

export default firebase;
